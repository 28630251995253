import {WIX_ECOM} from '@wix/app-definition-ids';
import {PageMap} from '@wix/wixstores-client-core/dist/src/constants';

export async function getEcomPages(sdk) {
  const tpaApplicationId = (await sdk.tpa.app.getDataByAppDefId('', WIX_ECOM)).applicationId;
  const allSitePages = await sdk.pages.data.getAll('');
  return allSitePages.filter((page) => page.tpaApplicationId === tpaApplicationId);
}

export async function getShopPagesTpaPageId(sdk) {
  const ecomPages = await getEcomPages(sdk);
  const shopPages = ecomPages.filter((page) => page.tpaPageId?.startsWith(PageMap.GALLERY));
  return shopPages.map((x) => x.tpaPageId);
}

export async function hasCategoryPageOnSite(sdk) {
  const ecomPages = await getEcomPages(sdk);
  return !!ecomPages.find((page) => page.tpaPageId?.startsWith(PageMap.CATEGORY));
}
